<template>
  <MainSlider />
  <CategoriesNav />
  <RegularSlider />
  <RecommendedCategories />
  <LeaveContacts />
  <OurObjects />
  <NewVideo />
  <Feedback />
  <section class="home-news">
    <BlogRecentlyPosts>
      <h4 class="home-news__title">{{ _T("@News & Articles") }}</h4>
      <p class="home-news__subtitle">
        {{
          _T(
            "@Everything you need to know about video surveillance from GreenVision",
          )
        }}
      </p>
    </BlogRecentlyPosts>
  </section>
  <Wholesale />
  <Fitters />
  <Questions />
  <Description />
</template>

<script setup>
import logoImg from "~~/assets/icons/Logo-GV.png";
import CategoriesNav from "~~/modules/home/components/CategoriesNav.vue";
import Description from "~~/modules/home/components/Description.vue";
import Feedback from "~~/modules/home/components/Feedback.vue";
import Fitters from "~~/modules/home/components/Fitters.vue";
import LeaveContacts from "~~/modules/home/components/LeaveContacts.vue";
import MainSlider from "~~/modules/home/components/mainSlider/MainSlider.vue";
import NewVideo from "~~/modules/home/components/NewVideo.vue";
import OurObjects from "~~/modules/home/components/ourObjects/OurObjects.vue";
import Questions from "~~/modules/home/components/questions/Questions.vue";
import RecommendedCategories from "~~/modules/home/components/recommendedCategories/RecommendedCategories.vue";
import RegularSlider from "~~/modules/home/components/regularSlider/RegularSlider.vue";
import Wholesale from "~~/modules/home/components/Wholesale.vue";
import BlogRecentlyPosts from "~/modules/blog/components/BlogRecentlyPosts.vue";

const config = useRuntimeConfig();
const route = useRoute();

useSeoMeta({
  title: _T("@Meta title"),
  description: _T("@Meta description"),
  ogUrl: config.public.appUrl + route.path,
  ogImage: logoImg,
});
</script>

<style lang="scss" scoped>
.home-news {
  @include flex-container(column, center, center);

  padding: 0 16px;
  margin-bottom: 32px;

  @include mobile {
    margin-bottom: 24px;
  }

  &__title {
    text-align: center;
    @include font(28, 39, 600);

    @include bigMobile {
      @include font(24, 34, 600);
    }

    @include mobile {
      @include font(20, 30, 600);
    }
  }

  &__subtitle {
    @include font(20, 30, 600);
    text-align: center;
    text-transform: uppercase;

    @include bigMobile {
      @include font(18, 26, 600);
    }

    @include mobile {
      @include font(16, 24, 600);
    }
  }
}
</style>
