<template>
  <section class="home-description">
    <div class="home-description__container">
      <h3 class="home-description__title">{{ _T(description.title) }}</h3>
      <div class="home-description__box">
        <div
          class="home-description__visible ui-rich-text"
          :class="{ active: isShowText }"
        >
          <h2>
            {{
              _T(
                "@GreenVision - video surveillance with Ukrainian intelligence",
              )
            }}
          </h2>
          <p>
            {{ _T("@If you need to buy video surveillance for your") }}
          </p>
          <p>
            <strong>{{
              _T("@Popular price available to every Ukrainian consumer")
            }}</strong>
          </p>
          <p>
            {{ _T("@Video surveillance for apartments, houses, offices") }}
          </p>
          <h3>
            {{ _T("@Reliable and efficient equipment to provide") }}
          </h3>
          <ol>
            <li>
              <p>
                <strong>{{ _T("@CCTV Cameras") }}</strong>
                {{ _T("@developed by our specialists answer") }}
              </p>
            </li>
            <li>
              <p>
                <strong>{{ _T("@CCTV recorder main page") }}</strong>
                {{ _T("@high-tech equipment") }}
              </p>
            </li>
            <li>
              <p>
                <strong>{{
                  _T("@Ready-made video surveillance systems for home/office")
                }}</strong>
                {{ _T("@CCTV kit in one box") }}
              </p>
            </li>
            <li>
              <p>
                <strong>{{
                  _T("@Wireless video surveillance for a private home")
                }}</strong>
                {{ _T("@Can be used indoors or outdoors") }}
              </p>
            </li>
          </ol>
          <p>
            {{ _T("@Quality assurance and technical support") }}
          </p>
          <p>
            {{ _T("@On the official GreenVision website, everyone can") }}
          </p>
        </div>
        <div
          class="home-description__show-box"
          :class="{ active: isShowText }"
          @click="showText"
        >
          <span
            class="home-description__show-text home-description__show-first"
            >{{ _T(description.btn[0]) }}</span
          >
          <span
            class="home-description__show-text home-description__show-second"
            >{{ _T(description.btn[1]) }}</span
          >
          <div
            class="home-description__arrow"
            :class="{ active: isShowText }"
          ></div>
        </div>
      </div>
      <div class="home-description__btn">
        <MyLink name="catalog">
          <button class="button-catalog">
            <img
              class="button-catalog__image"
              src="~~/assets/icons/catalog.svg"
              :alt="_T('@Goods catalog')"
            />
            <span class="button-catalog__title">{{
              _T("@Goods catalog")
            }}</span>
          </button>
        </MyLink>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useLanguageFilterStore } from "~~/store/languageFilterStore";

const languageStore = useLanguageFilterStore();
const { getUserLanguage } = storeToRefs(languageStore);

const isShowText = ref(false);

function showText() {
  isShowText.value = !isShowText.value;
}

const description = {
  title: "@Description",
  btn: ["@expand", "@collapse"],
};
</script>

<style lang="scss" scoped>
.home-description {
  padding: 0 16px;
  margin-bottom: 136px;

  @include mobile {
    margin-bottom: 125px;
  }

  &__container {
    @extend %width-main;
    margin: auto;
  }

  &__title {
    @include font(28, 39, 600);
    text-align: center;

    margin-bottom: 16px;

    @include bigMobile {
      @include font(26, 34, 600);
    }

    @include mobile {
      @include font(22, 28, 600);
      margin-bottom: 8px;
    }
  }

  &__box {
    border-radius: 12px;
    @extend %shadow;

    padding: 32px;
    margin-bottom: 16px;

    @include bigMobile {
      border-radius: 8px;
      padding: 16px;
    }
  }

  &__visible {
    height: 225px;

    margin-bottom: 8px;

    overflow: hidden;
    transition: height 0.4s ease-in-out;

    &.active {
      height: auto;
    }

    @include mobile {
      height: 340px;
    }
  }

  &__item {
    &:not(:last-of-type) {
      margin-bottom: 24px;
    }
  }

  &__subtitle {
    @include font(22, 26, 600);
    margin-bottom: 16px;

    @include bigMobile {
      @include font(20, 24, 600);
    }

    @include mobile {
      @include font(16, 22, 600);
    }
  }

  &__text {
    @include font(16, 20, 400);

    &:not(:last-of-type) {
      margin-bottom: 16px;
    }

    @include bigMobile {
      @include font(14, 18, 400);
    }

    @include mobile {
      @include font(12, 16, 400);
    }
  }

  &__show-box {
    cursor: pointer;
    max-width: 140px;

    position: relative;
    @include flex-container(row, center, center);

    padding: 15px 0;

    @include mobile {
      max-width: 115px;
    }

    &.active {
      & .home-description__show-first {
        bottom: -5px;
        transform: rotateX(90deg);
      }
      & .home-description__show-second {
        position: absolute;
        z-index: 3;
        top: 10px;

        transform: rotateX(0deg);
      }
    }

    & .home-description__show-second {
      position: absolute;
      top: -10px;
      left: 0;

      transform: rotateX(90deg);
      transition: transform 0.4s ease-in-out;
    }
  }

  &__show-first {
    position: absolute;
    bottom: 0;
    right: 0;

    transition: all 0.5s ease-in-out;
  }

  &__show-text {
    position: relative;

    @include font(20, 24, 400);
    color: var(--color-primary-base);

    margin-right: 10px;

    &::after {
      content: "";
      width: 100%;
      height: 1px;

      position: absolute;
      bottom: 0;
      left: 0;

      background-color: var(--color-primary-base);
    }

    &.home-description__show-view::after {
      content: none;
    }

    @include mobile {
      @include font(16, 22, 400);
    }
  }

  &__arrow {
    display: inline-block;

    border: solid var(--color-primary-base);
    border-width: 0 2px 2px 0;

    padding: 4px;

    transform: rotate(45deg);
    transition: transform 0.4s ease-in-out;

    &.active {
      transform: rotate(225deg);
    }
  }

  &__btn {
    @extend %flex-centre;

    @include font(18, 21, 400);

    & .button-catalog {
      padding: 10px 32px;
    }
  }
}

.button-catalog {
  width: max-content;

  @include flex-container(row, flex-start, center);

  background-color: var(--color-primary-base);
  border-radius: 6px;

  padding: 8px;
  gap: 8px;

  &:hover {
    background-color: var(--color-primary-light);
  }

  &:active {
    background-color: var(--color-primary-dark);
  }

  &__title {
    @include font(16, 18, 700);
    color: white;
    white-space: nowrap;
  }
}
</style>
