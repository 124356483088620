<template>
  <button class="button-green">
    <slot>{{ _T("@Send") }}</slot>
  </button>
</template>

<script setup></script>

<style lang="scss" scoped>
.button-green {
  width: 100%;
  padding: 8px 48px;

  background-color: white;
  border-radius: 6px;

  @include font(18, 25, 400);
  letter-spacing: 0.02em;
  color: var(--color-primary-base);

  transition: all 0.4s ease-in-out;

  &:hover {
    background-color: var(--color-primary-base);
    color: white;
  }

  &:active {
    background-color: var(--color-primary-dark);
    color: var(--color-primary-base);
  }

  &:disabled {
    cursor: auto;
    background-color: var(--color-sky-darker);
    color: white;
  }
}
</style>
