<template>
  <section class="contacts">
    <div class="contacts__wrapper">
      <form action="" class="contacts__form">
        <InputText
          v-model="form.firstName"
          class="contacts__input"
          :validate="v.firstName"
          :maxlength="255"
          :caption="_T('@Name')"
        />
        <InputPhone
          v-model="form.phone"
          class="contacts__input"
          :validate="v.phone"
          :caption="_T('@Phone number')"
        />
        <InputMail
          v-model="form.email"
          class="contacts__input"
          :validate="v.email"
          :caption="'E-mail'"
        />

        <div class="contacts__checkbox-wrp">
          <label
            class="contacts__agree"
            :class="{ error: v.isAgree.$invalid && v.isAgree.$dirty }"
          >
            <input v-model="form.isAgree" type="checkbox" />

            <span class="contacts__policy-text">
              <span>
                {{ _T("@I agree with") }}
                <MyLink name="delivery" target="_blank">
                  {{ _T("@terms of delivery") }}
                </MyLink>
                {{ _T("@and") }}
                <MyLink name="privacy-policy" target="_blank">
                  {{ _T("@privacy policy") }}
                </MyLink>
              </span>
            </span>
          </label>
          <ButtonFeedback @click.prevent="subscribe" />
        </div>
      </form>
    </div>
  </section>
</template>

<script setup>
import ButtonFeedback from "~~/modules/shared/components/buttons/ButtonFeedback.vue";
import InputMail from "~~/modules/shared/components/inputs/InputMail.vue";
import InputPhone from "~~/modules/shared/components/inputs/InputPhone.vue";
import InputText from "~~/modules/shared/components/inputs/InputText.vue";
import {
  successfulModal,
  useModalStore,
} from "~~/modules/shared/components/modals/store/modalStore";
import { formatPhone } from "~~/utils/phoneHelper";
import {
  emailValidate,
  maxLengthValidate,
  phoneValidate,
  requiredValidate,
  sameAsValidate,
} from "~~/utils/validators";
import useVuelidate from "@vuelidate/core";
import { feedbackFormStore } from "./feedbackFormStore";
import { useAPI } from "~/uses/useMyFetch";

const modalStore = useModalStore();
const formStore = feedbackFormStore();

const successfulData = {
  title: _T("@Newsletter subscription"),
  description: _T("@Thank you, our managers will contact you"),
  isWithTimeout: true,
};

const form = reactive({
  firstName: "",
  email: "",
  phone: "",
  isAgree: false,
});

const rules = computed(() => ({
  firstName: {
    required: requiredValidate(),
    maxLength: maxLengthValidate("@Maximum field length", 255),
  },
  email: {
    required: requiredValidate(),
    email: emailValidate(),
  },
  phone: {
    required: requiredValidate(),
    validatePhone: phoneValidate(),
  },

  isAgree: {
    sameAs: sameAsValidate("@You must accept the terms and conditions", true),
  },
}));

const v = useVuelidate(rules, form);

function subscribe() {
  if (formStore.isFormsReady()) {
    useAPI("/marketing/event", {
      method: "POST",
      body: {
        event: "cooperationRequestInitiated",
        email: form.email,
        phone: formatPhone(form.phone),
        isConfirmed: true,
        name: form.firstName,
      },
    })
      .then(() => {
        modalStore.toggleModal(successfulModal, successfulData);
        formStore.resetForms();
      })
      .catch(() => {});
  }
}

onMounted(() => {
  formStore.addForm({ v, id: "subscription" });
});

onUnmounted(() => {
  formStore.resetForms();
});
</script>

<style lang="scss" scoped>
.contacts {
  max-width: 410px;
  width: 100%;

  @include mobile {
    order: 2;
  }
  &.no-shadow {
    .contacts__wrapper {
      box-shadow: none;
    }
  }
  &__wrapper {
    background: linear-gradient(
        45deg,
        rgba(5, 132, 56, 0.6),
        rgba(65, 204, 121, 0.4)
      ),
      linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.5),
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0),
        rgba(224, 224, 224, 0.5)
      );
    box-shadow: 0 1px 25px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    padding: 16px 24px;
  }

  &__form {
    @include flex-container(column, flex-start);
    gap: 24px;
  }
  &__input {
    width: 100%;
  }

  &__policy-text {
    @include font(12, 16, 400);
    color: var(--color-ink-base);

    & span {
      text-align: left;
    }

    & a {
      color: white;
    }
  }

  &__checkbox-wrp {
    width: min(100%, 363px);
    align-self: center;

    @include flex-container(column, flex-start);
    gap: 24px;
  }

  &__agree {
    position: relative;

    align-self: flex-start;

    & input {
      height: 0;
      width: 0;

      position: absolute;
      left: 0;
      top: 50%;
      z-index: -1;

      opacity: 0;
      visibility: hidden;
    }

    & > span {
      @include flex-container(row, space-between, center);
      gap: 16px;
      margin-left: 8px;

      user-select: none;
    }

    & > span::before {
      content: "";
      display: inline-block;

      width: 24px;
      height: 24px;

      flex: 0 0 24px;

      border: 1px solid #cdcfd0;
      border-radius: 4px;

      background-repeat: no-repeat;
      background-position: center;

      transition: 0.2s ease;
    }

    &.error > span::before {
      border-color: #f73b2f;
    }

    & input:not(:checked) + span:hover::before {
      border-color: var(--color-primary-base);
    }

    & input:checked + span::before {
      border-color: var(--color-primary-base);
      background-color: var(--color-primary-base);
      background-image: url("~~/assets/icons/check-icon.svg");
    }
  }
}
</style>
