<template>
  <section class="home-objects">
    <h2 class="home-objects__title">{{ _T(ourObjects.title) }}</h2>
    <OurObjectsSlider
      :items="worksList"
      :city-title="ourObjects.cityTitle"
      :type-title="ourObjects.typeTitle"
    />
  </section>
</template>

<script setup>
import { useContentfulStore } from "~/store/contentfulStore";
import OurObjectsSlider from "~~/modules/home/components/ourObjects/OurObjectsSlider.vue";

const contentfulStore = useContentfulStore();
await contentfulStore.fetchWorks();

const worksList = contentfulStore.getWorks;

const ourObjects = {
  title: "@Our objects",
  typeTitle: "@Type of work",
  cityTitle: "@City",
};
</script>

<style lang="scss" scoped>
.home-objects {
  padding: 0 16px;
  margin-bottom: 32px;

  @include mobile {
    margin-bottom: 24px;
  }

  &__title {
    @include font(28, 39, 600);
    color: black;
    text-align: center;

    margin-bottom: 16px;

    @include bigMobile {
      @include font(26, 34, 600);
    }

    @include mobile {
      @include font(22, 28, 600);
    }
  }
}
</style>
