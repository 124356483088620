<template>
  <div class="home-objects__slider">
    <div class="home-objects__slider-wrap">
      <div
        ref="slider"
        class="home-objects__slides"
        @touchstart="handleTouchStart"
        @touchmove="handleTouchMove"
        @touchend="handleTouchEnd"
      >
        <div
          v-for="(item, index) in items"
          :key="index"
          class="home-objects__slide"
        >
          <img
            :src="useNormalizeCtfUrl(item.fields.img.ru.fields.file.ru.url)"
            :alt="item.fields.img.ru.fields.title.ru"
            class="home-objects__item-img"
          />
          <h3 class="home-objects__item-title">
            {{ item.fields.title?.[getUserLanguage.name] }}
          </h3>
          <div class="home-objects__item-description">
            <p>
              {{ _T(typeTitle) }}:
              {{ item.fields.typeOfWork?.[getUserLanguage.name] }}
            </p>
            <p>
              {{ _T(cityTitle) }}:
              {{ item.fields.location?.[getUserLanguage.name] }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="home-objects__buttons">
      <PaginationBtnArrow
        :direction-right="false"
        :disabled="sliderLength <= counterSlide"
        @click="prevSlide"
      />

      <PaginationBtnArrow
        :direction-right="true"
        :disabled="sliderLength <= counterSlide"
        @click="nextSlide"
      />
    </div>
  </div>
</template>

<script setup>
import PaginationBtnArrow from "~~/modules/shared/components/buttons/PaginationBtnArrow.vue";
import { useLanguageFilterStore } from "~~/store/languageFilterStore";
import { useNormalizeCtfUrl } from "~/uses/useNormalizeCtfUrl";

const languageStore = useLanguageFilterStore();
const { getUserLanguage } = storeToRefs(languageStore);

const { items, cityTitle, typeTitle } = defineProps({
  items: { type: Array, required: true },
  cityTitle: { type: String, required: true },
  typeTitle: { type: String, required: true },
});

const slider = ref(null);

const counter = ref(0);
const counterSlide = ref(0);

const sliderWidth = ref(0);
const sliderLength = ref(0);
const slideWidth = ref(0);

const translateX = ref(0);
const translateXVar = ref("");

const activeTouches = ref(false);
const mobileTranslateX = ref(0);
const startPosition = ref(0);
const difference = ref(0);

function getSliderValues() {
  sliderLength.value = items.length;
  sliderWidth.value = slider.value.offsetWidth;
  slideWidth.value = slider.value.children[0].offsetWidth;

  counterSlide.value = Math.round(sliderWidth.value / slideWidth.value);
  counter.value = 0;
  translateX.value = 0;
  translateXVar.value = `${translateX.value}`;
}

function nextSlide() {
  if (counter.value === sliderLength.value - counterSlide.value - 1) {
    counter.value++;
    translateX.value =
      slideWidth.value * counter.value -
      (sliderWidth.value - slideWidth.value * counterSlide.value);
  } else if (counter.value < sliderLength.value - counterSlide.value) {
    counter.value++;
    translateX.value = slideWidth.value * counter.value;
  } else {
    counter.value = 0;
    translateX.value = 0;
  }

  translateXVar.value = `-${translateX.value}px`;
}

function prevSlide() {
  if (counter.value === 0) {
    counter.value = sliderLength.value - counterSlide.value;
    translateX.value =
      slideWidth.value * counter.value -
      (sliderWidth.value - slideWidth.value * counterSlide.value);
  } else {
    counter.value--;
    translateX.value = slideWidth.value * counter.value;
  }
  translateXVar.value = `-${translateX.value}px`;
}

function handleTouchStart(event) {
  if (window.innerWidth < 1024) {
    activeTouches.value = true;
    mobileTranslateX.value = event.touches[0].clientX;
    startPosition.value = event.touches[0].clientX;
  }
}

function handleTouchMove(event) {
  if (window.innerWidth < 1024) {
    const positionMove = event.touches[0].clientX;
    const diff = positionMove - mobileTranslateX.value;
    const fingerSpace = 30;

    if (
      startPosition.value - positionMove < fingerSpace &&
      startPosition.value - positionMove > -fingerSpace
    ) {
      return false;
    } else {
      if (activeTouches.value) {
        if (!mobileTranslateX.value) return false;

        difference.value = diff;
        difference.value > 0 ? prevSlide() : nextSlide();

        mobileTranslateX.value = null;
      }
    }
  }
}

function handleTouchEnd() {
  if (window.innerWidth < 1024) {
    activeTouches.value = false;
  }
}

onMounted(() => {
  getSliderValues();

  window.addEventListener("resize", getSliderValues);
});

onUnmounted(() => {
  window.removeEventListener("resize", getSliderValues);
});
</script>

<style lang="scss" scoped>
.home-objects {
  &__slider {
    @extend %width-main;
    margin: auto;

    position: relative;

    color: black;

    @include mobile {
      padding-bottom: 52px;
    }
  }

  &__slider-wrap {
    @extend %width-main;

    position: relative;

    margin: auto;
    overflow: hidden;
  }

  &__slides {
    @include flex-container(row, flex-start);
    margin: 0 -9px;

    transform: translateX(v-bind(translateXVar));

    transition: transform 1s ease-in-out;
  }

  &__slide {
    flex: 0 0 33.333%;
    width: 468px;

    padding: 0 9px;
    transition: all 0.4s ease-in-out;

    @include bigMobile {
      flex: 0 0 50%;
    }

    @include mobile {
      flex: 0 0 90%;
    }
  }

  &__item-img {
    max-width: 468px;
    width: 100%;
    height: auto;

    border-radius: 8px;

    @include bigMobile {
      max-width: 100%;
    }
  }

  &__item-title {
    @include font(14, 20, 600);

    margin-top: 10px;
    margin-bottom: 8px;

    transition: all 0.4s ease-in-out;
  }

  &__item-description {
    @include font(12, 17, 400);

    & p:not(:last-of-type) {
      margin-bottom: 4px;
    }
  }

  &__buttons {
    width: 100%;

    position: absolute;
    z-index: 20;
    bottom: 50%;

    @include flex-container(row, space-between, center);

    @include mobile {
      @include flex-container(row, center, center);
      gap: 48px;

      bottom: 0;
    }
  }
}
</style>
