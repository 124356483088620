<template>
  <section class="home-questions">
    <div class="home-questions__container">
      <h2 class="home-questions__title">{{ _T("@FAQ") }}</h2>
      <QuestionsItem
        v-for="(item, index) in faqList"
        :key="index"
        :item="item"
      />
    </div>
  </section>
</template>

<script setup>
import { useContentfulStore } from "~/store/contentfulStore";

import QuestionsItem from "./QuestionsItem.vue";

const contentfulStore = useContentfulStore();
await contentfulStore.fetchFaq();

const faqList = contentfulStore.getFaq;
</script>

<style lang="scss" scoped>
.home-questions {
  background-color: rgba(0, 155, 62, 0.1);

  padding: 0 16px;
  margin-bottom: 32px;

  @include mobile {
    background-color: transparent;
    margin-bottom: 24px;
  }

  &__container {
    margin: auto;
    padding: 60px 0;

    @include bigMobile {
      padding: 30px 0;
    }

    @include mobile {
      background-color: rgba(0, 155, 62, 0.1);
      border-radius: 8px;

      padding: 16px 0;
    }
  }

  &__title {
    @include font(28, 39, 600);
    text-align: center;

    margin-bottom: 16px;

    @include bigMobile {
      @include font(24, 34, 600);
    }

    @include mobile {
      @include font(20, 30, 600);
    }
  }
}
</style>
