<template>
  <article class="main-slider">
    <div class="main-slider__arrows-wrapper">
      <div class="main-slider__wrapper">
        <div class="main-slider__slider">
          <div
            ref="sliderWidth"
            class="main-slider__slides"
            @touchstart="handleTouchStart"
            @touchmove="handleTouchMove"
            @touchend="handleTouchEnd"
          >
            <div
              v-for="(item, index) in filteredItems"
              :key="index"
              class="main-slider__slide"
            >
              <div class="main-slider__slide-wrapper">
                <div class="main-slider__main-info">
                  <NuxtLink
                    :to="`${getUserLanguage.urlPrefix}/${
                      item.fields.url[getUserLanguage.name]
                    }`"
                    class="main-slider__title"
                    :style="{ '--colorTitle': getColorTitle(item) }"
                  >
                    {{ item.fields.title?.[getUserLanguage.name] }}
                  </NuxtLink>

                  <div class="main-slider__image">
                    <span class="main-slider__image-border"></span>
                    <img
                      v-if="item.fields.imgMain"
                      :src="
                        useNormalizeCtfUrl(
                          item.fields.imgMain.ru.fields.file.ru.url,
                        )
                      "
                      :alt="item.fields.title?.[getUserLanguage.name]"
                    />
                    <img
                      v-else
                      src="/img/photo-plug.svg"
                      :alt="item.fields.title?.[getUserLanguage.name]"
                    />
                  </div>

                  <p
                    class="main-slider__subtitle"
                    :style="{ '--colorDescription': getColorDescription(item) }"
                  >
                    {{ item.fields.description?.[getUserLanguage.name] }}
                  </p>

                  <NuxtLink
                    class="main-slider__details"
                    :style="[
                      { '--color-text': getColorBtnText(item) },
                      { '--bg-color': getColorBtnBg(item) },
                    ]"
                    :to="`${getUserLanguage.urlPrefix}/${
                      item.fields.url[getUserLanguage.name]
                    }`"
                  >
                    {{ item.fields.btn?.[getUserLanguage.name] }}
                  </NuxtLink>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="main-slider__pagination">
          <ul class="main-slider__nav">
            <li
              v-for="(slide, index) in items"
              :key="index"
              class="main-slider__nav-item"
              :class="{ active: index === counter }"
              @click="activePagination(index)"
            />
          </ul>
        </div>
      </div>

      <div class="main-slider__buttons">
        <div class="main-slider__buttons-left">
          <PaginationBtnArrow
            :direction-right="false"
            :main-slider="mainSlider"
            @click="prevSlide"
          />
        </div>
        <div class="main-slider__buttons-right">
          <PaginationBtnArrow
            :direction-right="true"
            :main-slider="mainSlider"
            @click="nextSlide"
          />
        </div>
      </div>
    </div>
  </article>
</template>

<script setup>
import { useContentfulStore } from "~/store/contentfulStore";
import PaginationBtnArrow from "~~/modules/shared/components/buttons/PaginationBtnArrow.vue";
import { useLanguageFilterStore } from "~~/store/languageFilterStore";
import { addScopedEventListener } from "~~/utils/eventListener";
import { useNormalizeCtfUrl } from "~/uses/useNormalizeCtfUrl";

const languageStore = useLanguageFilterStore();
const { getUserLanguage } = storeToRefs(languageStore);

const contentful = useContentfulStore();
await contentful.fetchSlider();

const config = useRuntimeConfig();

const mainSlider = ref(true);

const items = contentful.getSlider;
const sliderWidth = ref(null);
const bgImage = ref("black");
const bgColor = ref("#009b3e");

const filteredItems = ref([]);

const counter = ref(0);
const sliderLength = ref(0);
const slideWidth = ref(0);
const translateX = ref(0);
const translateXVar = ref("");

const activeTouches = ref(false);
const mobileTranslateX = ref(0);
const startPosition = ref(0);
const difference = ref(0);
const interval = ref(-1);

function getColorTitle(item) {
  return item.fields.colorTitle ? item.fields.colorTitle.ru : "white";
}

function getColorBtnText(item) {
  return item.fields.colotBtn ? item.fields.colotBtn.ru : "white";
}

function getColorBtnBg(item) {
  return item.fields.colorBtnBack ? item.fields.colorBtnBack.ru : "#3600a9";
}

function getColorDescription(item) {
  return item.fields.colorText ? item.fields.colorText.ru : "white";
}

function getBgImage(item) {
  return item.fields.backgroundImage
    ? `url('${useNormalizeCtfUrl(
        item.fields.backgroundImage.ru.fields.file.ru.url,
      )}')`
    : item.fields.colorBack.ru;
}

function getBgColor(item) {
  return item.fields.colorBack?.ru ? item.fields.colorBack?.ru : "#009b3e";
}

function getSliderValues() {
  filterItems();
  sliderLength.value = filteredItems.value.length;
  slideWidth.value = sliderWidth.value.scrollWidth / sliderLength.value;
  bgImage.value = getBgImage(filteredItems.value[counter.value]);
  bgColor.value = getBgColor(filteredItems.value[counter.value]);
  actualTransition();
}

function actualTransition() {
  translateX.value = slideWidth.value * counter.value;
  translateXVar.value = `-${translateX.value}px`;
}

function filterItems() {
  filteredItems.value = items.sort(
    (a, b) => a.fields.position.ru - b.fields.position.ru,
  );
  return filteredItems.value;
}

function prevSlide() {
  if (counter.value > 0) {
    counter.value--;
    translateX.value = slideWidth.value * counter.value;
  } else {
    counter.value = sliderLength.value - 1;
    translateX.value = counter.value * slideWidth.value;
  }
  bgImage.value = getBgImage(filteredItems.value[counter.value]);
  bgColor.value = getBgColor(filteredItems.value[counter.value]);
  translateXVar.value = `-${translateX.value}px`;
}

function nextSlide() {
  if (counter.value < sliderLength.value - 1) {
    counter.value++;
    translateX.value = slideWidth.value * counter.value;
  } else {
    counter.value = 0;
    translateX.value = 0;
  }
  bgImage.value = getBgImage(filteredItems.value[counter.value]);
  bgColor.value = getBgColor(filteredItems.value[counter.value]);
  translateXVar.value = `-${translateX.value}px`;
}

function activePagination(index) {
  counter.value = index;
  translateX.value = slideWidth.value * counter.value;
  bgImage.value = getBgImage(filteredItems.value[counter.value]);
  bgColor.value = getBgColor(filteredItems.value[counter.value]);
  translateXVar.value = `-${translateX.value}px`;
}

function handleTouchStart(event) {
  if (window.innerWidth < 1024) {
    activeTouches.value = true;
    mobileTranslateX.value = event.touches[0].clientX;
    startPosition.value = event.touches[0].clientX;
  }
}

function handleTouchMove(event) {
  if (window.innerWidth < 1024) {
    const positionMove = event.touches[0].clientX;
    const diff = positionMove - mobileTranslateX.value;
    const fingerSpace = 30;

    if (
      startPosition.value - positionMove < fingerSpace &&
      startPosition.value - positionMove > -fingerSpace
    ) {
      return false;
    } else {
      if (activeTouches.value) {
        if (!mobileTranslateX.value) return false;

        difference.value = diff;
        difference.value > 0 ? prevSlide() : nextSlide();

        mobileTranslateX.value = null;
      }
    }
  }
}

function handleTouchEnd() {
  if (window.innerWidth < 1024) {
    activeTouches.value = false;
  }
}

function endInterval(interval) {
  clearInterval(interval);
}

watch(counter, () => {
  endInterval(interval.value);
  interval.value = setInterval(nextSlide, 5000);
});

onMounted(() => {
  getSliderValues();
  addScopedEventListener(window, "resize", getSliderValues);
});

onUpdated(() => {
  getSliderValues();
});
</script>

<style lang="scss" scoped>
.main-slider {
  padding: 0 25px;
  margin-bottom: 32px;
  margin-top: 28px;

  @include bigMobile {
    padding: 0 16px;
    margin-top: 72px;
  }

  &__arrows-wrapper {
    position: relative;
    @extend %width-main;
    margin: auto;
  }

  &__wrapper {
    @extend %width-main;
    min-height: 460px;

    background-repeat: no-repeat;
    background-size: cover;
    background-image: v-bind(bgImage);
    background-color: v-bind(bgColor);

    transition: background 0.4s ease-in-out;

    position: relative;

    @include flex-container(column, center, center);

    border-radius: 40px;
    padding: 24px 0;
    margin: 0 auto;

    overflow: hidden;

    @include bigMobile {
      padding-top: 50px;
      padding-bottom: 32px;
      gap: 48px;
    }
    @include mobile {
      padding-bottom: 16px;
      gap: 24px;
    }
  }

  &__slider {
    @extend %width-main;
  }

  &__slides {
    @include flex-container(row, flex-start, center);

    transform: translateX(v-bind(translateXVar));

    transition: transform 0.5s ease-in-out;
  }

  &__slide {
    flex: 0 0 100%;

    padding: 0 64px;

    @include bigMobile {
      padding: 0;
    }
  }

  &__slide-wrapper {
    max-width: 1130px;
    width: 100%;

    position: relative;

    margin: auto;
  }

  &__main-info {
    max-width: 460px;
    width: 100%;

    @include flex-container(column, flex-start, flex-start);

    gap: 16px;

    @include bigMobile {
      max-width: 100%;

      @include flex-container(column, center, center);
      gap: 24px;
    }
  }

  &__title {
    --colorTitle: white;

    @include font(36, 43, 700);

    color: var(--colorTitle);

    @include bigMobile {
      @include font(24, 34, 700);
      letter-spacing: 0.02em;
      text-align: center;
    }

    @include bigMobile {
      max-width: 90%;
      @include font(18, 22, 700);
    }
  }

  &__image {
    max-width: 404px;
    width: 100%;
    height: 404px;

    position: absolute;
    top: 50%;
    right: 0;

    @include flex-container(column, center, center);

    transform: translateY(-50%);

    padding: 16px;
    margin: auto;

    @include bigMobile {
      max-width: 219px;
      height: auto;

      position: static;

      @include flex-container(column, center, center);

      transform: translateY(0);

      padding: 0;
    }
  }

  &__subtitle {
    --colorDescription: white;

    @include font(16, 22, 400);
    color: var(--colorDescription);
    letter-spacing: 0.02em;

    @include bigMobile {
      @include font(14, 20, 400);
      width: 90%;
      text-align: center;
    }

    @include mobile {
      @include font(12, 18, 400);
    }
  }

  &__details {
    --color-text: white;
    --bg-color: var(--color-primary-base);

    max-width: 236px;
    width: 100%;

    @include flex-container(row, center, center);

    position: relative;

    @include font(18, 24);
    letter-spacing: 0.02em;
    color: var(--color-text);

    background-color: var(--bg-color);

    border-radius: 8px;

    padding: 8px 16px;

    @include smallScreen {
      @include font(16, 24);
    }

    @include bigMobile {
      align-self: flex-start;

      margin: 0 auto;
    }

    @include mobile {
      @include font(14, 20);
    }
  }

  &__pagination {
    position: absolute;
    bottom: 16px;

    @include bigMobile {
      position: static;
    }
  }

  &__nav {
    @include flex-container(row, flex-start, center);

    gap: 16px;
  }

  &__nav-item {
    width: 14px;
    height: 14px;

    background-color: white;
    border-radius: 50%;

    cursor: pointer;

    transition: background-color 0.2s ease-in-out;

    &.active {
      background-color: var(--color-blue-darkest);
      border-radius: 10px;
      width: 28px;
    }

    @include bigMobile {
      width: 12px;
      height: 12px;
    }
  }

  &__buttons {
    &-left {
      position: absolute;
      top: 50%;
      z-index: 80;
      left: -24px;
      transform: translateY(-50%);
    }
    &-right {
      position: absolute;
      top: 50%;
      z-index: 80;
      right: -24px;
      transform: translateY(-50%);
    }
    @include bigMobile {
      display: none;
    }
  }
}
</style>
