<template>
  <div>
    <div class="home-questions__item" @click="handleClick">
      <div class="home-questions__box" :class="{ active: isShow }">
        <div class="home-questions__subtitle-box">
          <h3 class="home-questions__subtitle">
            {{ item.fields.title?.[getUserLanguage.name] }}
          </h3>
          <div class="home-questions__plus-box">
            <span
              class="home-questions__plus"
              :class="{ active: isShow }"
            ></span>
          </div>
        </div>
        <Transition name="descriptions">
          <div v-if="isShow" class="home-questions__description">
            <div
              class="home-questions__description-item ui-rich-text"
              v-html="
                item.fields.description?.[getUserLanguage.name].content[0]
                  .content[0].value
              "
            ></div>
          </div>
        </Transition>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useLanguageFilterStore } from "~~/store/languageFilterStore";

const languageStore = useLanguageFilterStore();
const { getUserLanguage } = storeToRefs(languageStore);

const isShow = ref(false);

const props = defineProps({
  item: { type: Object, required: true },
});

function handleClick() {
  isShow.value = !isShow.value;
}
</script>

<style lang="scss" scoped>
.home-questions {
  &__item {
    max-width: 912px;
    width: 100%;

    padding: 16px;
    margin: auto;
    margin-bottom: 5px;

    @include mobile {
      margin-bottom: 0;
    }
  }

  &__box {
    cursor: pointer;

    &:hover {
      & .home-questions__subtitle {
        color: var(--color-primary-base);
      }

      & .home-questions__plus,
      & .home-questions__plus::before {
        background-color: var(--color-primary-base);
        transform: rotate(90deg) scale(1.1);
      }
    }
    &.active:hover {
      & .home-questions__plus {
        transform: rotate(180deg) scale(1.3);
      }
    }
  }

  &__subtitle-box {
    @include flex-container(row, space-between, center);
  }

  &__subtitle {
    width: 85%;

    @include font(20, 28, 600);

    transition: color 0.4s ease-in-out;

    @include mobile {
      @include font(16, 22, 600);
    }
  }

  &__description {
    width: 75%;

    @include mobile {
      width: 100%;
    }
  }

  &__description-item {
    @include font(16, 22, 400);

    margin-top: 16px;

    &li:not(:last-of-type) {
      margin-bottom: 16px;
    }

    @include mobile {
      @include font(14, 20, 400);
    }
  }

  &__plus-box {
    cursor: pointer;
    width: 32px;
    height: 32px;

    position: relative;
    @extend %flex-centre;
    align-self: start;

    padding: 8px;
  }

  &__plus {
    width: 16px;
    height: 2px;
    display: block;

    background-color: var(--color-ink-base);
    transition:
      transform 0.4s ease-in-out,
      background-color 0.4s ease-in-out;

    &::before {
      content: "";
      width: 16px;
      height: 2px;

      display: block;
      position: absolute;
      z-index: 2;

      background-color: var(--color-ink-base);

      transform: rotate(90deg);
      transition:
        transform 0.4s ease-in-out,
        background-color 0.4s ease-in-out;
    }

    &.active {
      &::before {
        display: none;
      }
    }
  }
}

.descriptions-enter-from {
  transform: translateY(-30px);
  opacity: 0;
}

.descriptions-enter-to {
  transform: translateY(0px);
  transition:
    transform 0.4s ease,
    opacity 0.4s ease;
}

.descriptions-leave-to {
  transform: translateY(-30px);
  transition:
    transform 0.4s ease,
    opacity 0.4s ease;
  opacity: 0;
}
</style>
