<template>
  <section class="home-wholesale">
    <div class="home-wholesale__container">
      <div class="home-wholesale__title-box">
        <h2 class="home-wholesale__title">B2b</h2>
      </div>
      <h4 class="home-wholesale__subtitle">
        {{ _T("@GreenVision - manufacturer and direct supplier of equipment") }}
      </h4>
      <p class="home-wholesale__text">
        {{ _T("@Products are certified in Ukraine") }}
      </p>
      <div class="home-wholesale__content">
        <div
          v-for="(item, index) in itemsB2b"
          :key="index"
          class="home-wholesale__item"
        >
          <img
            class="home-wholesale__svg"
            :src="`/img/${item.icon}.svg`"
            :alt="item.icon"
          />
          <h3 class="home-wholesale__item-title">
            {{ _T(item.title) }}
          </h3>
          <p class="home-wholesale__item-description">
            {{ _T(item.subtitle) }}
          </p>
        </div>
      </div>
      <div class="home-wholesale__btn-box">
        <NuxtLink :to="'http://b2b.logicpower.ua'" class="home-wholesale__link">
          <button class="home-wholesale__btn">
            {{ _T("@Btn Go") }}
          </button>
        </NuxtLink>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useLanguageFilterStore } from "~~/store/languageFilterStore";

const languageStore = useLanguageFilterStore();

const { getUserLanguage } = storeToRefs(languageStore);

const itemsB2b = [
  {
    icon: "b2b-icon-1",
    title: "@Wide range of products",
    subtitle: "@Fast payback, high margins, quality assurance",
  },
  {
    icon: "b2b-icon-2",
    title: "@Automatic uploading",
    subtitle: "@No need to manually add",
  },
  {
    icon: "b2b-icon-3",
    title: "@Information and technical support",
    subtitle: "@We provide text and video user instructions",
  },
];
</script>

<style lang="scss" scoped>
.home-wholesale {
  background-image: url(~~/assets/icons/b2b-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  padding: 0 16px;
  margin-bottom: 32px;

  @include mobile {
    margin-bottom: 24px;
  }

  &__container {
    @extend %width-main;

    color: white;

    margin: auto;
    padding: 58px 0;

    @include bigMobile {
      padding: 14px 0 34px;
    }
  }

  &__title-box {
    text-align: center;
  }

  &__title {
    display: inline-block;

    @include font(64, 75, 900);
    text-align: center;
    text-transform: uppercase;

    border-radius: 50%;
    background: linear-gradient(
        135deg,
        rgba(255, 255, 255, 0.5),
        rgba(255, 255, 255, 0),
        rgba(0, 155, 62, 0),
        rgba(0, 155, 62, 0.5)
      ),
      linear-gradient(rgba(255, 255, 255, 0.54), rgba(255, 255, 255, 0.1));

    margin-bottom: 24px;
    padding: 41px 16px;

    @include bigMobile {
      @include font(50, 60, 900);

      padding: 35px 16px;
    }

    @include mobile {
      @include font(44, 53, 600);

      padding: 32px 16px;
      margin-bottom: 16px;
    }
  }

  &__subtitle {
    @include font(32, 42, 600);
    text-align: center;

    margin-bottom: 16px;

    @include bigMobile {
      @include font(26, 34, 600);
    }

    @include mobile {
      @include font(22, 29, 600);
    }
  }

  &__text {
    max-width: 850px;

    @include font(20, 30, 600);
    text-transform: uppercase;
    text-align: center;

    margin: auto;
    margin-bottom: 24px;

    @include bigMobile {
      @include font(18, 26, 600);
    }

    @include mobile {
      @include font(16, 24, 600);
      margin-bottom: 16px;
    }
  }

  &__content {
    @include flex-container(row, center, center);

    margin-bottom: 24px;

    @include bigMobile {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;

      padding-top: 45px;
    }

    @include mobile {
      display: block;
      margin-bottom: 16px;
    }
  }

  &__item {
    width: 343px;
    height: 237px;

    position: relative;
    @include flex-container(column, center, center);

    text-align: center;

    background: linear-gradient(
        135deg,
        rgba(255, 255, 255, 0.5),
        rgba(255, 255, 255, 0),
        rgba(0, 155, 62, 0),
        rgba(0, 155, 62, 0.5)
      ),
      linear-gradient(rgba(243, 243, 243, 0.4), rgba(243, 243, 243, 0.1));

    box-shadow: 0px 1px 25px rgba(0, 0, 0, 0.05);
    border-radius: 20px;

    margin: 45px 16px 0 16px;
    padding: 0px 16px;

    @include bigMobile {
      height: 100%;
      width: auto;

      margin-top: 0;
      padding: 50px 16px 20px 16px;
    }

    @include mobile {
      max-width: 343px;
      width: 100%;

      padding: 40px 16px;
      margin: auto;

      &:not(:last-of-type) {
        margin-bottom: 46px;
      }
    }
  }

  &__svg {
    position: absolute;
    z-index: 10;
    top: -45px;
    left: 50%;

    transform: translateX(-50%);

    @include bigMobile {
      width: 80px;
      height: 80px;

      top: -40px;
    }

    @include mobile {
      width: 60px;
      height: 60px;

      top: -30px;
    }
  }

  &__item-title {
    @include font(20, 28, 600);
    margin-bottom: 8px;

    @include bigMobile {
      @include font(18, 25, 600);
    }
  }

  &__item-description {
    @include font(16, 22, 500);

    @include bigMobile {
      @include font(14, 20, 400);
    }
  }

  &__btn-box {
    text-align: center;
  }

  &__btn {
    display: inline-block;

    @include font(18, 25, 400);
    color: var(--color-primary-base);

    background-color: white;
    border-radius: 8px;

    padding: 16px 144px;

    transition:
      color 0.4s ease-in-out,
      background-color 0.4s ease-in-out;

    &:hover {
      color: white;
      background-color: var(--color-primary-base);
    }

    @include bigMobile {
      padding: 14px 125px;
    }

    @include mobile {
      max-width: 343px;
      width: 100%;

      padding: 14px 0;
    }
  }

  &__link {
    padding: 21px 0;

    @include bigMobile {
      padding: 18px 0px;
    }
  }
}
</style>
